/* Styles for the Micromodal-based modals */
:root {
  /* Exit buttons are sometimes Cancel, but sometimes
       other "Nah, forget it" actions. */
  --color-exit-button-text: hsl(0deg 0% 0%);
  --color-exit-button-border: hsl(300deg 2% 11% / 30%);
  --color-exit-button-background: hsl(226deg 1% 42% / 20%);
  --color-exit-button-background-interactive: hsl(226deg 1% 42% / 27%);
}

.modal__overlay {
  position: fixed;
  inset: 0;
  background: hsl(0deg 0% 0% / 60%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 105;
}

.modal__container {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-modal);
  max-width: calc(100% - 32px);
  max-height: 96%;
  width: 32.5rem;
  border-radius: 4px;
  box-sizing: border-box;
}

.modal__header {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 24px;
}

.modal__title {
  margin: 0;
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.25;
  overflow: hidden;
  word-wrap: break-word;

  /* help_link_widget margin for the fa-circle-o. */
  .help_link_widget {
    margin-left: 5px;
  }
}

.user-profile-name-heading {
  padding-right: 1rem;
  max-width: 80%;
  display: flex;
  align-items: center;
  gap: 10px;
  /* This line-height is to increase the vertical clickable areas on the icons. */
  line-height: 28px;

  .user-profile-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-profile-manage-own-edit-button,
  .user-profile-manage-others-edit-button,
  .user-profile-manage-own-copy-link-button {
    color: hsl(200deg 100% 50%);
    cursor: pointer;

    &:hover {
      color: hsl(200deg 100% 25%);
    }
  }
}

.modal__close {
  &::before {
    content: "\2715";
  }
  margin-right: -4px;
  background: transparent;
  border: 0;

  &:hover {
    background: hsl(0deg 0% 90%);
  }
}

.modal__content {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  overflow-y: auto;
  padding: 2px 24px;
  line-height: 1.5;

  &.simplebar-scrollable-y + .modal__footer {
    border-top: 1px solid hsl(0deg 0% 87%);
  }
}

.modal__btn {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background-color: hsl(0deg 0% 90%);
  border-radius: 0.25rem;
  border-width: 0;
  cursor: pointer;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.modal__btn:focus,
.modal__btn:hover {
  transform: scale(1.05);
}

.dialog_exit_button {
  color: var(--color-exit-button-text);
  background: var(--color-exit-button-background);
  border: 1px solid var(--color-exit-button-border);

  &:hover {
    background: var(--color-exit-button-background-interactive);
  }
}

.dialog_submit_button {
  margin-left: 12px;
  background-color: rgba(103, 57, 177, 0.81);
  color: hsl(0deg 0% 100%) !important;
}

#user-profile-modal .save-success {
  vertical-align: top;
  background-color: transparent;
  border-radius: 4px;
  margin-right: 10px;
  color: hsl(156deg 30% 50%);
  padding: 0.5rem 1rem;
  line-height: 1.15;

  &:not(:empty) {
    border: 1px solid hsl(156deg 30% 50%);
  }
}

#read_receipts_error,
#dialog_error {
  margin-bottom: 10px;
}

#archive-stream-modal .notification_stream_archive_warning {
  margin-bottom: 0;
}

#read_receipts_modal {
  .modal__container {
    width: 360px;

    .modal__content {
      /* When showing read receipts, we use simplebar
            to make the list scrollable.  It requires this to
            be flex. */
      display: flex;

      /* Setting a minimum height prevents the loading indicator
               appearing/disappearing from resizing the modal in the
               common case that one is requesting read receipts for
               direct messages. */
      min-height: 120px;
      /* Setting a maximum height is just for aesthetics; the modal looks
               weird if its aspect ratio gets too stretched. */
      max-height: 480px;

      /* For the notification bot error, we want to keep the modal clean and small.
               The 16px padding is intended to match the padding at the top of the modal. */
      &.compact {
        min-height: unset;
        padding-bottom: 16px;
      }
    }
  }

  .modal__header {
    padding-bottom: 0;
  }

  & hr {
    margin: 10px 0;
  }

  .modal__content {
    padding: 0 24px 8px;
  }

  .loading_indicator {
    margin: auto;
  }

  .read_receipts_list {
    margin-left: 0;

    & li {
      .read_receipts_user_avatar {
        display: inline-block;
        height: 20px;
        width: 20px;
        position: relative;
        right: 8px;
        border-radius: 4px;
      }

      margin: 2px 0;
      list-style-type: none;
      overflow-x: hidden;
      padding-left: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      line-height: 26px;

      &:hover {
        background-color: hsl(0deg 0% 0% / 5%);
      }

      &:active,
      &:focus {
        background-color: hsl(0deg 0% 0% / 10%);
        outline: none;
      }
    }
  }
}

.email_field {
  margin-top: 10px;

  .email_field_textarea {
    width: 97%;
    resize: vertical;
    margin-bottom: 10px;
  }

  .border-top {
    border-top: 1px solid hsl(300deg 2% 11% / 30%);
    padding-top: 10px;
  }

  .email-body {
    margin-left: 20px;
    margin-top: 20px;
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.micromodal {
  display: none;
}

.micromodal.modal--opening,
.micromodal.modal--open {
  display: block;
}

.micromodal[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut 75ms cubic-bezier(0, 0, 0.2, 1);
}

.micromodal[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn 120ms cubic-bezier(0, 0, 0.2, 1);
}

.micromodal[aria-hidden="true"] .modal__container {
  animation: mmfadeOut 75ms cubic-bezier(0, 0, 0.2, 1);
}

.micromodal[aria-hidden="false"] .modal__container {
  animation: mmfadeIn 120ms cubic-bezier(0, 0, 0.2, 1);
}

.micromodal .modal__container,
.micromodal .modal__overlay {
  will-change: transform;
}

.modal__spinner .loading_indicator_spinner {
  height: 16px;

  & path {
    fill: hsl(0deg 0% 100%);
  }
}

.modal__spinner {
  display: flex;
  justify-content: center;
}

#copy_email_address_modal {
  width: 800px;

  .inline {
    display: inline;
  }

  .question-which-parts {
    padding-bottom: 10px;
  }

  .stream-email-header {
    font-size: 18px;
  }
}

.modal_select {
  height: 30px;
  width: 220px;
  padding: 0 25px 0 6px;
  color: hsl(0deg 0% 33%);
  border-radius: 4px;
  border: 1px solid hsl(0deg 0% 80%);
  cursor: pointer;
  background-color: hsl(0deg 0% 100%);

  &:disabled {
    cursor: not-allowed;

    /* The background-color of select elements inside modal is different than the others in
        settings pages, because the background of the modal is brighter than the setting page. */
    background-color: hsl(0deg 0% 90%);
  }
}

.modal-field-label {
  margin-bottom: var(--margin-bottom-field-description);
}

.dropdown-widget-button {
  width: 206px;
}

.modal_password_input,
.modal_url_input,
.modal_text_input {
  padding: 4px 6px;
  color: hsl(0deg 0% 33%);
  border-radius: 4px;
  border: 1px solid hsl(0deg 0% 80%);
  box-shadow: inset 0 1px 1px hsl(0deg 0% 0% / 7.5%);
  transition:
    border linear 0.2s,
    box-shadow linear 0.2s;
  margin-bottom: 10px;
  width: 206px;

  &:focus {
    border-color: hsl(206deg 80% 62% / 80%);
    outline: 0;
    box-shadow:
      inset 0 1px 1px hsl(0deg 0% 0% / 7.5%),
      0 0 8px hsl(206deg 80% 62% / 60%);
  }
}

#add-poll-modal {
  /* this height allows 3-4 option rows
    to fit in without need for scrolling */
  height: 450px;
  overflow: hidden;

  .modal__content {
    flex-grow: 1;

    .simplebar-content {
      box-sizing: border-box;
      height: 100%;
    }
  }

  #add-poll-form {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    .poll-label {
      font-weight: bold;
      margin: 5px 0;
    }

    .poll-question-input-container {
      display: flex;
      margin-bottom: 10px;

      #poll-question-input {
        flex-grow: 1;
      }
    }

    .poll-options-list {
      margin: 0;
      height: 0;
      overflow: auto;
      flex-grow: 1;

      .option-row {
        list-style-type: none;
        cursor: move;
        margin-top: 10px;
        padding: 0;
        display: flex;
        align-items: center;
        gap: 10px;

        .drag-icon {
          color: hsl(0deg 0% 75%);
        }

        .poll-option-input {
          flex-grow: 1;
        }
      }

      .option-row:first-child {
        margin-top: 0;
      }

      .option-row:last-child {
        cursor: default;

        .delete-option {
          visibility: hidden;
        }

        .drag-icon {
          visibility: hidden;
        }
      }
    }
  }
}

#introduce-zulip-view-modal {
  i {
    vertical-align: middle;
  }

  .keyboard-button {
    color: var(--color-hotkey-hint);
    font-size: 12px;
    font-weight: 500;
    padding: 2px 4px;
    border-radius: 3px;
    border: 1px solid var(--color-hotkey-hint);
  }
}

#generate-integration-url-modal {
  #integrations-event-container {
    .integration-all-events-buttons {
      display: flex;
      gap: 10px;
      margin: 5px 0 10px;
    }

    #integrations-event-options {
      .integration-event-wrapper {
        margin: 5px 0;
      }

      .integration-event-name {
        word-break: break-all;
      }
    }
  }
}

#invite_users_option_tabs_container {
  margin-bottom: 20px;
}
